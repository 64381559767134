import React from "react";
import { graphql } from "gatsby";

import Layout from "../templates/Layout";
import Avatar from '../components/avatar/Avatar';


export const pageQuery = graphql`
    query Committee {
        allAuthorJson(sort: {fields: order, order: ASC}) {
            edges {
                node {
                    friendlyName
                    role
                }
            }
        }
    }
`


class Committee extends React.Component {
    render() {
        const committee = this.props.data.allAuthorJson.edges;

        return (
            <Layout
                title="Committee"
                location={this.props.location}
            >
                <p>The Dalgety Bay Horticulture Society committee members for the 2021/22 period.</p>
                {committee
                    .map(({ node }) => {
                        return (
                            <Avatar key={node.friendlyName} person={node} />
                        )
                    })
                }
            </Layout>
        )
    }
}
        
export default Committee