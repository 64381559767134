import React from "react";

import * as styles from "./Avatar.module.css";

const Avatar = (props) => {
    return (
        <div className={styles.wrapper}>
            <h4 className={styles.name}>{props.person.friendlyName}</h4>
            <h5 className={styles.role}>{props.person.role}</h5>
        </div>
    )
};

export default Avatar;